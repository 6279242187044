import React from "react";
import { Helmet } from "react-helmet";

function Layout(props) {
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang: "en",
                }}
            >
                <meta charSet="utf-8" />
                <title>The Open Institute</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                <meta
                    name="description"
                    content="The Open Institute for Social Science, a research and teaching cooperative."
                ></meta>
                <link rel="apple-touch-icon" href="/favicon.png"></link>
                <meta name="icon" href="/favicon.png" />
            </Helmet>
            <div>{props.children}</div>
        </>
    );
}

export default Layout;
